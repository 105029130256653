const brand = process.env.NEXT_PUBLIC_BRAND || '';

export const createCountryDestinationRequest = (): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/destinations?geo_types=countries&brand=${brand}`,
		{
			method: 'GET',
			headers: { Brand: brand },
		},
	);
};

export const createRegionDestinationRequest = (countrySlug: string): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/destinations?geo_types=regions&country_slug=${countrySlug}&brand=${brand}`,
		{
			method: 'GET',
			headers: { Brand: brand },
		},
	);
};

export const createCityDestinationRequest = (regionSlug: string): RequestInfo => {
	return new Request(
		`${process.env.NEXT_PUBLIC_NBC_ENDPOINT_URL}/api/v1/destinations?geo_types=cities&region_slug=${regionSlug}&brand=${brand}`,
		{
			method: 'GET',
			headers: { Brand: brand },
		},
	);
};
