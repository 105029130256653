import { sortObject } from '@vakantiesnl/services/src/util/sortObjectKeys';
import { Search } from '@vakantiesnl/types';

export const createFlashDealsRequest = (body: Search.VaknlSearchBody): RequestInfo => {
	const item = JSON.stringify(sortObject(body));

	return new Request(`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/deals`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: item,
	});
};
