import { mapCompactAccommodation } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { FlashDeals } from '@vakantiesnl/types';

import { filterInvalidFlashdeal } from '../util/flashdeals/flashdeals';

/** Specific for zoover, for vakanties we need to use mapAccommodation */
export const mapZooverFlashDealsResponse = (response: FlashDeals.RawFlashDeal[]): FlashDeals.FlashDeal[] =>
	response
		.filter((flashDeal) => flashDeal.accommodation.nbc && flashDeal.accommodation.offers?.length)
		.map(
			(flashDeal): FlashDeals.FlashDeal => ({
				accommodation: mapCompactAccommodation(
					flashDeal.accommodation.giata_id,
					flashDeal.accommodation.nbc,
					flashDeal.accommodation.offers,
				),
				flashDeal: mapFlashDealItem(flashDeal.deal),
			}),
		)
		.filter(filterInvalidFlashdeal);

export const mapFlashDealItem = (rawFlashDeal: FlashDeals.RawFlashDealItem): FlashDeals.FlashDealItem => ({
	airlines: rawFlashDeal.airlines || undefined,
	departureAirports: rawFlashDeal.departure_airports || undefined,
	departureDateFrom: rawFlashDeal.departure_date_from,
	departureDateTo: rawFlashDeal.departure_date_to,
	discountAmount: rawFlashDeal.discount_amount,
	discountAmountType: rawFlashDeal.discount_amount_type,
	duration: rawFlashDeal.duration?.toString(),
	expirationDate: rawFlashDeal.expiration_date,
	giataId: rawFlashDeal.giata_id,
	tourOperators: rawFlashDeal.tour_operators || undefined,
	mealplans: rawFlashDeal.mealplans || undefined,
	roomTypes: rawFlashDeal.room_types || undefined,
	validFrom: rawFlashDeal.valid_from,
});
